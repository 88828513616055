<template>
  <div>

      All categories will be run twice daily at 12 and 0 UTC or on request

      <h3>Categories</h3>
      <div v-for="(competition, index) in competitions">
        <button v-on:click="selected_competition=index">{{ competition.name }}</button>
      </div>

      <template v-if="competitions.length">
        <h3>Competition-specific Rules</h3>
        <ul v-for="rule in competitions[selected_competition].rules">
          <li>{{ rule }}</li>
        </ul>

        <h3>Highscores as of {{ competitions[selected_competition].time }}</h3>

        <table>
      		<thead>
            <th>Place</th>
      			<th>Trueskill</th>
      			<th>Program</th>
            <th>Creator</th>
            <th>Size</th>
            <th>Cycles</th>
      		</thead>
      		<tbody>
      			<tr v-for="(score,index) in competitions[selected_competition].highscores">
              <td>{{ index+1 }}</td>
      				<td>{{ score.score }}</td>
      				<td>{{ score.name }}</td>
              <td>{{ score.creator }}</td>
              <td>{{ score.size }}</td>
              <td>{{ score.steps }}</td>
      			</tr>
      		</tbody>
      	</table>

        <h3>Games</h3>
        <!-- TODO Use game.id here-->
        <ul v-for="(game, index) in competitions[selected_competition].games" v-bind:key="game.id">
          <div>
            <h4>Game {{ index+1 }}</h4>
            <ChessGame :id="'board_' + index" :ref="'board_' + index" :gameinfo="game" style="width: 400px"></ChessGame>
          </div>
        </ul>

      </template>

      Loading...
      <!--
      DejaVu Sans

      Most recent games

      Matrix

      Performance
      -->


  </div>
</template>


<script>
const axios = require('axios');

import ChessGame from '@/components/ChessGame.vue'

export default {
  name: 'Competitions',
  components: {
    ChessGame
  },
  props: {
    msg: String
  },
  data: function() {
    return {
      competitions: [],
      selected_competition: 0,
    }
  },
  created() {
    this.fetchData();
  },
  watch: {
    "$route": "fetchData"
  },
  methods: {
    fetchData() {
      axios
      .get('/competitions.json')
      .then(response => {
        this.competitions = response.data;
        // TODO pray that the .js is loaded
        //drawStuff()
      })
    },
  },
  mounted() {

  },
  updated() {

  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
