<template>
    <div>



<h3>Write your own program</h3>
<h4>Option 1 - Online with <a target="_blank" href="https://webassembly.studio/?f=qf2d60wxhg8">webassembly.studio</a></h4>

Click the link above, it contains example code
<br><br>
To compile your modified code, save it (with ctrl+s or the Save button on the top right), and click "Build".
<br><br>
You can download the resulting .wasm by right clicking on main.wasm, then Download on the left.
<br><br>
This compiler already does a pretty good job at optimizing the binary size.
<br><br>
<h4>Option 2 - Local compilation</h4>
If you want to compile and/or test locally, check out these
<a target="_blank" href="https://github.com/void4/relich">AssemblyScript</a> or
<a target="_blank" href="https://github.com/void4/rlc_rust">Rust</a> guides.
<br><br>
Feel free to write your program in any other language, I just need the resulting .wasm binary.

<br><br>

<h3>Your programs</h3>

<div v-if="this.$parent.loggedin">

<table>
  <thead>
    <th>Name</th>
    <th>Size (bytes)</th>
    <th></th>
    <th></th>
  </thead>
<tbody>
  <tr v-for="(program,index) in programs" v-bind:key="program.name" v-if="program.own">
    <td>{{ program.name }}</td>
    <td>{{ program.size }}</td>
    <td><button @click="playAgainst(program.creator, program.name)">Play against</td>
    <td><button @click="testSelf(program.creator, program.name)">Selfplay</button></td>
    <td><button @click="testRandom(program.creator, program.name)">Test Random</button></td>
    <td><button @click="testEngine(program.creator, program.name)">Stockfish</button></td>
    <td><button @click="deleteProgram(program.name)">Delete</button></td>
  </tr>
</tbody>
</table>
<br>


<h4>Submit your compiled .wasm binary</h4>

<input type="file" ref="file" name="file" />
<button @click="uploadProgram">Upload Program</button>
<br>
</div>

<div v-else>
  <div>
  Sign in to upload your own programs.
  </div>
</div>

<h3>Other programs</h3>
<table>
  <thead>
    <th>Name</th>
    <th>Size (bytes)</th>
    <th>Creator</th>
    <th></th>
    <th></th>
  </thead>
<tbody>
  <tr v-for="(program,index) in programs" v-bind:key="program.name" v-if="!program.own">
    <td>{{ program.name }}</td>
    <td>{{ program.size }}</td>
    <td>{{ program.creator }}</td>
    <td><button @click="playAgainst(program.creator, program.name)">Play against</td>
    <td><button @click="testSelf(program.creator, program.name)">Selfplay</button></td>
    <td><button @click="testRandom(program.creator, program.name)">Random</button></td>
    <td><button @click="testEngine(program.creator, program.name)">Stockfish</button></td>
  </tr>
</tbody>
</table>

<div v-if="game">
  <h4>Game</h4>
  <ChessGame :gameinfo="game" style="width: 400px" v-bind:key="game.id" :elochange="false"></ChessGame>
  <br>
</div>


</div>
</template>
<script>
const axios = require('axios');

import ChessGame from '@/components/ChessGame.vue'

export default {
  name: 'Programs',
  props: {
    game: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    ChessGame
  },
  data: function() {return {
    programs: []
  }},
  created() {
    this.fetchData();
  },
  watch: {
    "$route": "fetchData"
  },
  methods: {
    uploadProgram() {
      var file = this.$refs.file.files[0]
      var formData = new FormData()
      formData.append("file", file)
      axios
      .post('/upload', formData, {headers: {'Content-Type': 'multipart/form-data'}})
      .then(response => {
        this.fetchData()
      })
    },
    deleteProgram(name) {
      axios
      .post('/delete', {name})
      .then(response => {this.fetchData()})
    },
    fetchData() {
      axios
      .get('/programs.json')
      .then(response => (this.programs = response.data))
    },
    testSelf(creator, name) {
      axios
      .post('/testPlay.json', {creator, name, selfplay:true})
      .then(response => (this.game = response.data))
    },
    testRandom(creator, name) {
      axios
      .post('/testPlay.json', {creator, name, selfplay:false})
      .then(response => {this.game = response.data; console.log(this.game)})
    },
    testEngine(creator, name) {
      axios
      .post('/testEngine.json', {creator, name})
      .then(response => {this.game = response.data; console.log(this.game)})
    },
    playAgainst(creator, name) {
      /*
      WebAssembly.instantiateStreaming(fetch(name), importObject)
      .then(results => {
        // Do something with the results!
      });
      */
      axios
      .post('/playAgainst', {creator, name})
      .then(response => {this.game = response.data; console.log(this.game)})
    },
  },
  mounted() {

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
