<template>
	<div id="app">
		<title>ReLiCh</title>

		<div class="header">
			<div class="h1">Resource Limited Chess</div>
			<div class="navtab">
				<router-link to="/">About</router-link>
				<router-link to="/programs">Programs</router-link>
				<router-link to="/competitions">Competitions</router-link>
				<a href="https://discord.gg/CyW4hgz4US">Discord</a>
				<div v-if="!loggedin">
				<router-link to="/login">Sign in</router-link>
				</div>
				<div v-else>
				<a href="/user/sign-out">Sign out</a>
				</div>
			</div>
		</div>
		<br>
		<br><br><br>

		<keep-alive v-if="loggedin">
			<router-view></router-view>
		</keep-alive>

		<router-view v-else></router-view>

		<br>

	<!--
		Most recent games

		Matrix

		Performance/*DejaVu Sans*/
	-->

	</div>
</template>

<script>
const axios = require('axios');

export default {
	name: 'App',

	props: {
		loggedin: {
			type: Boolean,
			default: false,
		}
	},

	methods: {
		//this.$forceUpdate();
		checkLogin() {
			axios
			.post('/loggedin')
			.then(response => {this.loggedin = response.data; console.log(response.data)})
		}
	},

	created() {
		console.log("App.vue created")
		this.checkLogin()
	}
}

</script>

<style>


#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin-top: 60px;
}

html {
	overflow-y: scroll;
}

.header a {
	text-decoration: none;
	color: #111;
}

.header a:link a:visited {
	color: black;
}

.router-link {
	color: black;
}

.router-link-exact-active {
	background-color: #ddd;
}

body {
	margin: auto;
	width: 885px;
	font: 14px arial;
}


tr:nth-child(even) {background: #f4f4f4}
tr:nth-child(odd) {background: #FFF}



.y-axis line {
	opacity:.2;
}
.y-axis path {
	display:none;
}
.line {
	fill: none;
	stroke-width: 1.5px;
	opacity:.75;
}
.overlay {
	fill: none;
	pointer-events: all;
}
.lineHoverText {
	text-shadow:
	-2px -2px 0 #fff,
	2px -2px 0 #fff,
	-2px 2px 0 #fff,
	2px 2px 0 #fff;
}
.hoverCircle {
	opacity: .75;
}

.navtab {
	float: right;
	width: 60%;
	justify-content: space-around;
	display: flex;
	flex-direction: row;
}

.navtab a {
	font-size: 1.5em;
	text-decoration: none;
}

.header {
	clear: both;
}

.h1 {
	font-weight: bold;
	font-size: 2em;
	float: left;
	display: inline-block;
}

.tutorial {
	width: 100%;
}
</style>
