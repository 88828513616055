<template>
  <div class="blue merida">
    {{ gameinfo.result }}
    <p v-if="elochange">
      <br>
      {{ gameinfo.elochange }}
    </p>
    <br>
    <div style="display:flex">
    <div style="">
      <div ref="board" class="cg-board-wrap" @wheel.prevent="wheel"></div><br>
      <button v-on:click="start()">start</button>
      <button v-on:click="back()">back</button>
      <button v-on:click="next()">next</button>
      <button v-on:click="end()">end</button>
      <br>
      <button v-on:click="lichessImport()">Analyze on lichess</button>
    </div>
<pre>
{{ gameinfo.pgn }}
</pre>
</div>

    <!--Use buttons here-->
    <select ref="select">
      <option value="_1">Cycles</option>
      <option value="_2">Legal moves</option>
    </select>
    <svg ref="chart" width="850" height="410"></svg>
  </div>
</template>

<script>
const axios = require('axios');
const d3 = require('@/components/assets/js/d3.v5.min.js');
import Chess from 'chess.js'
import {Chessground} from 'chessground'

export default {
  name: 'chessboard',
  props: {
    gameinfo: {
      type: Object,
      default: () => {}
    },
    gameid : {
      type: Number,
      default: null,
    },
    elochange: {
      type: Boolean,
      default: true,
    },
    fen: {
      type: String,
      default: '',
    },
    free: {
      type: Boolean,
      default: false,
    },
    showThreats: {
      type: Boolean,
      default: false,
    },
    onPromotion: {
      type: Function,
      default: () => 'q',
    },
    orientation: {
      type: String,
      default: 'white',
    },
    history: {
      type: Array,
      default: () => [],
    }
  },
  /*
  data: function() {
    return {
      future: []
    }
  },
  */
  watch: {
    gameinfo: function (newGameInfo) {
      if (newGameInfo.id != this.gameid) {
        console.log("New gameid", newGameInfo.id)
        this.loadPosition()
        this.gameid = newGameInfo.id
      } else {
        this.move()
      }
    },
    fen: function (newFen) {
      this.fen = newFen
      //this.loadPosition()
    },
    orientation: function (orientation) {
      this.orientation = orientation
      this.loadPosition()
    },
    showThreats: function (st) {
      this.showThreats = st
      if (this.showThreats) {
        this.paintThreats()
      }
    },
  },

  methods: {

    lichessImport(i) {
      //.post('https://lichess.org/api/import', {pgn:pgn})
      //
      var newWindow = window.open("", "_blank");
      var pgn = this.gameinfo.pgn
      //console.log("PGN", pgn)
      axios
      .post('/lichessImport', {pgn})
      .then(response => {
        //console.log(response.data.url)//window.location =
        //window.open(response.data.url);
        newWindow.location = response.data.url
      })
    },
    createChart(el, select, stats) {
      chart(el, select, stats)
    },


    possibleMoves () {
      const dests = {}
      this.game.SQUARES.forEach(s => {
        const ms = this.game.moves({square: s, verbose: true})
        if (ms.length) dests[s] = ms.map(m => m.to)
      })
      return dests
    },
    opponentMoves () {
      let originalPGN = this.game.pgn()
      let tokens = this.game.fen().split(' ')
      tokens[1] = tokens[1] === 'w' ? 'b' : 'w'
      tokens = tokens.join(' ')
      let valid = this.game.load(tokens)
      if (valid) {
        let moves = this.game.moves({verbose: true})
        this.game.load_pgn(originalPGN)
        return moves
      } else {
        return []
      }
    },
    toColor () {
      return (this.game.turn() === 'w') ? 'white' : 'black'
    },
    paintThreats () {
      let moves = this.game.moves({verbose: true})
      let threats = []
      moves.forEach(function (move) {
        threats.push({orig: move.to, brush: 'yellow'})

        if (move['captured']) {
          threats.push({orig: move.from, dest: move.to, brush: 'red'})
        }
        if (move['san'].includes('+')) {
          threats.push({orig: move.from, dest: move.to, brush: 'blue'})
        }
      })
      this.board.setShapes(threats)
    },
    calculatePromotions () {
      let moves = this.game.moves({verbose: true})
      this.promotions = []
      for (let move of moves) {
        if (move.promotion) {
          this.promotions.push(move)
        }
      }
    },
    isPromotion   (orig, dest) {
      let filteredPromotions = this.promotions.filter(move => move.from === orig && move.to === dest)
      return filteredPromotions.length > 0 // The current movement is a promotion
    },
    changeTurn () {
      return (orig, dest, metadata) => {
        var move = {from: orig, to: dest}
        if (this.isPromotion(orig, dest)) {
          this.promoteTo = this.onPromotion()
          move["promotion"] = this.promoteTo
        }
        console.log("changeTurn", orig, dest, metadata)
        this.game.move(move) // promote to queen for simplicity
        this.board.set({
          fen: this.game.fen(),
          turnColor: this.toColor(),
          movable: {
            color: this.toColor(),
            //dests: this.possibleMoves(),
          },
        })
        this.calculatePromotions()
        this.afterMove()

        var uci = move.from + move.to
        if (move.promotion) {
          uci += move.promotion
        }
        axios
        .post('/playMove', {move:uci})
        .then(response => {this.gameinfo = response.data; console.log(this.gameinfo)})
      }
    },
    afterMove () {
      console.log("afterMove")
      if (this.showThreats) {
        this.paintThreats()
      }
      let threats = this.countThreats(this.toColor()) || {}
      threats['history'] = this.game.history()
      threats['fen'] = this.game.fen()
      this.$emit('onMove', threats)
    },
    countThreats (color) {
      let threats = {}
      let captures = 0
      let checks = 0
      let moves = this.game.moves({verbose: true})
      if (color !== this.toColor()) {
        moves = this.opponentMoves()
      }

      if (moves.length === 0) {
        return null // It´s an invalid position
      }

      moves.forEach(function (move) {
        if (move['captured']) {
          captures++
        }
        if (move['san'].includes('+')) {
          checks++
        }
      })

      threats[`legal_${color}`] = uniques(moves.map(x => x.from + x.to)).length // promotions count as 4 moves. This remove those duplicates moves.
      threats[`checks_${color}`] = checks
      threats[`threat_${color}`] = captures
      threats[`turn`] = color
      return threats
    },
    loadPosition () { // set a default value for the configuration object itself to allow call to loadPosition()
      //this.game.load(this.fen)
      console.log("loadPosition")
      this.game.load_pgn(this.gameinfo.pgn)
      this.board = Chessground(this.$refs.board, {
        fen: this.game.fen(),
        turnColor: this.toColor(),
        movable: {
          color: this.toColor(),
          //free: this.free,
          //dests: this.possibleMoves(),
          events: { after: this.changeTurn() }
        },
        orientation: this.orientation,
      })
      this.afterMove()
      this.current_move = this.game.history().length - 1

      console.log(this.toColor())
      console.log(this.possibleMoves())

      var moves = this.game.history();
      console.log("MOVES", moves)
      var tmp = new Chess()
      this.history = [tmp.fen()]
      for (var i=0;i<moves.length;i++) {
        tmp.move(moves[i])
        //this.future.push(tmp.fen())
        this.history.push(tmp.fen())
      }
    },
    move() {
      console.log("move")
      for (var i=0;i<this.gameinfo.uci.length-this.game.history().length;i++) {
        var uci = this.gameinfo.uci[this.game.history().length+i]
        this.game.move({from:uci[0], to:uci[1], promotion:uci[2]})
        this.history.push(this.game.fen())
        this.board.move(uci[0], uci[1])
        if (uci[2].length > 0) {
          this.board.set({fen:this.game.fen()})
        }
        this.createChart(this.$refs.chart, this.$refs.select, this.gameinfo.stats)
      }
    },
    redraw() {
      this.board = Chessground(this.$refs.board, {fen:this.history[this.current_move]})//no new instance, animate?
      console.log("redraw")
      // TODO have to set move handlers again?
      // TODO disable history step for manual games?
    },
    start() {
      this.current_move = 0
      this.redraw()
    },

    end() {
      this.current_move = this.history.length-1
      this.redraw()
    },

    back() {
      if (this.current_move <= 0) {
        return
      }
      this.current_move -= 1
      this.redraw()
    },

    next() {
      if (this.current_move >= this.history.length-1) {
        return
      }
      this.current_move += 1
      this.redraw()
    },

    wheel(event) {
      event.preventDefault()
      event.stopPropagation();
      if (event.deltaY < 0) {
        this.back()
      } else {
        this.next()
      }
    }
  },



  mounted () {
    setTimeout(this.loadPosition, 0)
    this.createChart(this.$refs.chart, this.$refs.select, this.gameinfo.stats)
  },
  updated() {
    //this.loadPosition()
  },
  created () {
    this.current_move = 0
    this.game = new Chess()
    this.board = null
    this.promotions = []
    this.promoteTo = 'q'
  },
}

function uniques (arr) {
  let uniqueArray = arr.filter(function (elem, index, self) {
    return index === self.indexOf(elem)
  })
  return uniqueArray
}

function chart(svgel, selectel, jdata) {

  //console.log(data)
  //console.log(svg)
  //Doesn't work, isn't the live instance
  //console.log(Competition.data())

  //console.log(window.vue)

  if (!jdata["white"].hasOwnProperty("cycles") || !jdata["black"].hasOwnProperty("cycles")) {
    return
  }

  var data = []

  var last = {"date":0, "white_1": 0, "black_1": 0,  "white_2": 0, "black_2": 0}

  for (var i=0;i<jdata.white.cycles.length+jdata.black.cycles.length;i++) {
    var bw = ["white", "black"][i%2]
    last[bw+"_1"] = jdata[bw]["cycles"][Math.floor(i/2)]
    last[bw+"_2"] = jdata[bw]["legal"][Math.floor(i/2)]
    last["date"] = i
    data.push(JSON.parse(JSON.stringify(last)))
  }

  //console.log(data)

  var keys = Object.keys(last).slice(1)//data.columns.slice(1);

  var parseTime = d3.timeParse("%Y%m%d"),
    formatDate = d3.timeFormat("%Y-%m-%d"),
    bisectDate = d3.bisector(d => d.date).left,
    formatValue = d3.format(",.0f");

  /*
  data.forEach(function(d) {
    d.date = parseTime(d.date);
    return d;
  })
  */

  //svg = d3.select("#chart"),

  var svg = d3.select(svgel)

  var margin = {top: 15, right: 35, bottom: 15, left: 35},
    width = +svg.attr("width") - margin.left - margin.right,
    height = +svg.attr("height") - margin.top - margin.bottom;

  var x = d3.scaleLinear()
    .rangeRound([margin.left, width - margin.right])
    .domain(d3.extent(data, d => d.date))

  var y = d3.scaleLinear()
    .rangeRound([height - margin.bottom, margin.top]);

  var z = d3.scaleOrdinal(d3.schemeCategory10);

  var line = d3.line()
    .curve(d3.curveCardinal)
    .x(d => x(d.date))
    .y(d => y(d.degrees));

  svg.append("g")
    .attr("class","x-axis")
    .attr("transform", "translate(0," + (height - margin.bottom) + ")")
    .call(d3.axisBottom(x));//.tickFormat(d3.timeFormat("%b"))

  svg.append("g")
    .attr("class", "y-axis")
    .attr("transform", "translate(" + margin.left + ",0)");

  var focus = svg.append("g")
    .attr("class", "focus")
    .style("display", "none");

  focus.append("line").attr("class", "lineHover")
    .style("stroke", "#999")
    .attr("stroke-width", 1)
    .style("shape-rendering", "crispEdges")
    .style("opacity", 0.5)
    .attr("y1", -height)
    .attr("y2",0);

  focus.append("text").attr("class", "lineHoverDate")
    .attr("text-anchor", "middle")
    .attr("font-size", 12);

  var overlay = svg.append("rect")
    .attr("class", "overlay")
    .attr("x", margin.left)
    .attr("width", width - margin.right - margin.left)
    .attr("height", height)

  update(d3.select(selectel).property('value'), 0);

  function update(input, speed) {

    var copy = keys.filter(f => f.includes(input))

    var cities = copy.map(function(id) {
      return {
        id: id,
        values: data.map(d => {return {date: d.date, degrees: +d[id]}})
      };
    });

    y.domain([
      d3.min(cities, d => d3.min(d.values, c => c.degrees)),
      d3.max(cities, d => d3.max(d.values, c => c.degrees))
    ]).nice();

    svg.selectAll(".y-axis").transition()
      .duration(speed)
      .call(d3.axisLeft(y).tickSize(-width + margin.right + margin.left))

    var city = svg.selectAll(".cities")
      .data(cities);

    city.exit().remove();

    city.enter().insert("g", ".focus").append("path")
      .attr("class", "line cities")
      .style("stroke", d => z(d.id))
      .merge(city)
    .transition().duration(speed)
      .attr("d", d => line(d.values))

    tooltip(copy);
  }

  function tooltip(copy) {

    var labels = focus.selectAll(".lineHoverText")
      .data(copy)

    labels.enter().append("text")
      .attr("class", "lineHoverText")
      .style("fill", d => z(d))
      .attr("text-anchor", "start")
      .attr("font-size",12)
      .attr("dy", (_, i) => 1 + i * 2 + "em")
      .merge(labels);

    var circles = focus.selectAll(".hoverCircle")
      .data(copy)

    circles.enter().append("circle")
      .attr("class", "hoverCircle")
      .style("fill", d => z(d))
      .attr("r", 2.5)
      .merge(circles);

    svg.selectAll(".overlay")
      .on("mouseover", function() { focus.style("display", null); })
      .on("mouseout", function() { focus.style("display", "none"); })
      .on("mousemove", mousemove);

    function mousemove() {

      var x0 = x.invert(d3.mouse(this)[0]),
        i = bisectDate(data, x0, 1),
        d0 = data[i - 1],
        d1 = data[i],
        d = x0 - d0.date > d1.date - x0 ? d1 : d0;

      focus.select(".lineHover")
        .attr("transform", "translate(" + x(d.date) + "," + height + ")");

      focus.select(".lineHoverDate")
        .attr("transform",
          "translate(" + x(d.date) + "," + (height + margin.bottom) + ")")
        .text(d.date);

      focus.selectAll(".hoverCircle")
        .attr("cy", e => y(d[e]))
        .attr("cx", x(d.date));

      focus.selectAll(".lineHoverText")
        .attr("transform",
          "translate(" + (x(d.date)) + "," + height / 2.5 + ")")
        .text(e => e + " " + formatValue(d[e]));

      x(d.date) > (width - width / 4)
        ? focus.selectAll("text.lineHoverText")
          .attr("text-anchor", "end")
          .attr("dx", -10)
        : focus.selectAll("text.lineHoverText")
          .attr("text-anchor", "start")
          .attr("dx", 10)
    }
  }

  var selectbox = d3.select(selectel)
    .on("change", function() {
      update(this.value, 750);
    })
}


</script>
<style>

pre {
  white-space: pre-wrap;
  word-break: keep-all;
  margin-left: 10%;
  margin-top: 0;
}


/*@import './assets/css/theme.css';

 * Board
 */

 /*
  * Chessground base css properties.
  *
  * You need to include the css files in themes folder in order to have the
  * board and pieces displayed!
  */

 .cg-wrap {
   width: 320px;
   height: 320px;
   position: relative;
   display: block;
 }

 cg-helper {
   position: absolute;
   width: 12.5%;
   padding-bottom: 12.5%;
   display: table; /* hack: round to full pixel size in chrome */
   bottom: 0;
 }

 cg-container {
   position: absolute;
   width: 800%;
   height: 800%;
   display: block;
   bottom: 0;
 }

 cg-board {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   line-height: 0;
   background-size: cover;
   cursor: pointer;
 }
 cg-board square {
   position: absolute;
   top: 0;
   left: 0;
   width: 12.5%;
   height: 12.5%;
   pointer-events: none;
 }
 cg-board square.move-dest {
   background: radial-gradient(rgba(20, 85, 30, 0.5) 22%, #208530 0, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0) 0);
   pointer-events: auto;
 }
 cg-board square.premove-dest {
   background: radial-gradient(rgba(20, 30, 85, 0.5) 22%, #203085 0, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0) 0);
 }
 cg-board square.oc.move-dest {
   background: radial-gradient(transparent 0%, transparent 80%, rgba(20, 85, 0, 0.3) 80%);
 }
 cg-board square.oc.premove-dest {
   background: radial-gradient(transparent 0%, transparent 80%, rgba(20, 30, 85, 0.2) 80%);
 }
 cg-board square.move-dest:hover {
   background: rgba(20, 85, 30, 0.3);
 }
 cg-board square.premove-dest:hover {
   background: rgba(20, 30, 85, 0.2);
 }
 cg-board square.last-move {
   will-change: transform;
   background-color: rgba(155, 199, 0, 0.41);
 }
 cg-board square.selected {
   background-color: rgba(20, 85, 30, 0.5);
 }
 cg-board square.check {
   background: radial-gradient(ellipse at center, rgba(255, 0, 0, 1) 0%, rgba(231, 0, 0, 1) 25%, rgba(169, 0, 0, 0) 89%, rgba(158, 0, 0, 0) 100%);
 }
 cg-board square.current-premove {
   background-color: rgba(20, 30, 85, 0.5);
 }
 .cg-wrap piece {
   position: absolute;
   top: 0;
   left: 0;
   width: 12.5%;
   height: 12.5%;
   background-size: cover;
   z-index: 2;
   will-change: transform;
   pointer-events: none;
 }
 cg-board piece.dragging {
   cursor: move;
   z-index: 9;
 }
 cg-board piece.anim {
   z-index: 8;
 }
 cg-board piece.fading {
   z-index: 1;
   opacity: 0.5;
 }
 .cg-wrap square.move-dest:hover {
   background-color: rgba(20, 85, 30, 0.3);
 }
 .cg-wrap piece.ghost {
   opacity: 0.3;
 }
 .cg-wrap svg {
   overflow: hidden;
   position: relative;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   pointer-events: none;
   z-index: 2;
   opacity: 0.6;
 }
 .cg-wrap svg image {
   opacity: 0.5;
 }
 .cg-wrap coords {
   position: absolute;
   display: flex;
   pointer-events: none;
   opacity: 0.8;
   font-size: 9px;
 }
 .cg-wrap coords.ranks {
   right: -15px;
   top: 0;
   flex-flow: column-reverse;
   height: 100%;
   width: 12px;
 }
 .cg-wrap coords.ranks.black {
   flex-flow: column;
 }
 .cg-wrap coords.files {
   bottom: -16px;
   left: 0;
   flex-flow: row;
   width: 100%;
   height: 16px;
   text-transform: uppercase;
   text-align: center;
 }
 .cg-wrap coords.files.black {
   flex-flow: row-reverse;
 }
 .cg-wrap coords coord {
   flex: 1 1 auto;
 }
 .cg-wrap coords.ranks coord {
   transform: translateY(39%);
 }





.blue .cg-wrap {
  background-image: url('./assets/img/board/blue.svg');
}

.merida .cg-wrap piece.pawn.white {
  background-image: url('./assets/img/pieces/merida/wP.svg');
}
.merida .cg-wrap piece.bishop.white {
  background-image: url('./assets/img/pieces/merida/wB.svg');
}
.merida .cg-wrap piece.knight.white {
  background-image: url('./assets/img/pieces/merida/wN.svg');
}
.merida .cg-wrap piece.rook.white {
  background-image: url('./assets/img/pieces/merida/wR.svg');
}
.merida .cg-wrap piece.queen.white {
  background-image: url('./assets/img/pieces/merida/wQ.svg');
}
.merida .cg-wrap piece.king.white {
  background-image: url('./assets/img/pieces/merida/wK.svg');
}
.merida .cg-wrap piece.pawn.black {
  background-image: url('./assets/img/pieces/merida/bP.svg');
}
.merida .cg-wrap piece.bishop.black {
  background-image: url('./assets/img/pieces/merida/bB.svg');
}
.merida .cg-wrap piece.knight.black {
  background-image: url('./assets/img/pieces/merida/bN.svg');
}
.merida .cg-wrap piece.rook.black {
  background-image: url('./assets/img/pieces/merida/bR.svg');
}
.merida .cg-wrap piece.queen.black {
  background-image: url('./assets/img/pieces/merida/bQ.svg');
}
.merida .cg-wrap piece.king.black {
  background-image: url('./assets/img/pieces/merida/bK.svg');
}

</style>
