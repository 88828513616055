import Vue from 'vue'
import App from './App.vue'

import About from './components/About.vue'
import Programs from './components/Programs.vue'
import Competitions from './components/Competitions.vue'
import Login from './components/Login.vue'
import Register from './components/Register.vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter)

const axios = require('axios');

const routes = [
	{path:"/", component:About},
	{path:"/programs", component:Programs},
	{path:"/competitions", component:Competitions},
	{path:"/login", component:Login},
	{path:"/register", component:Register},
]

// TODO: set selected router link background also adjust buttons a bit down
const router = new VueRouter({
	routes,
	mode: 'history',
})
Vue.config.productionTip = false

window.vue = new Vue({

  render: h => h(App),
  router,


	components: {
		About,
		Programs,
		Competitions,
		Login,
		Register
	},


	created() {
		console.log("app created")



		var scripts = [
			"https://d3js.org/d3.v5.min.js",
			//"/js/chessground.js"
		];
		scripts.forEach(script => {
			let tag = document.createElement("script");
			tag.setAttribute("src", script);
			document.head.appendChild(tag);
			tag.onload = function() {
				//drawStuff()
			}
		});
	},
	beforeUpdate() {
		console.log("app before update")
	},
	updated() {
		console.log("app before update")
	},
}).$mount('#app')
