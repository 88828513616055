<template>
<div>
  <router-link to="/login">Already have an account? Sign in</router-link>

  <form v-on:submit.prevent="login">
    <input type="hidden" name="csrf_token">
    <input type="text" name="username" ref="username" placeholder="username">
    <input type="password" name="password" ref="password" placeholder="password">
    <label>
    <input type="checkbox" name="remember_me" ref="remember_me">
    Remember me
    </label>
    <input type="submit" value="Register">
  </form>
  {{ msg }}
</div>
</template>

<script>
const axios = require('axios');

export default {
  name: 'Register',
  props: {
    msg: String
  },
  methods: {
    login() {
      //{headers: {'Content-Type': 'application/json'}})
      axios
      .post('/user/register', {next:"/", reg_next:"/", csrf_token:"", username:this.$refs.username.value, password:this.$refs.password.value, remember_me:this.$refs.remember_me.value})
      .then(response => {
        console.log(response)
        var registered = response.data.registered
        var loggedin = response.data.loggedin
        this.msg = response.data.status
        this.$parent.loggedin = loggedin;
        if (loggedin) {
          this.$router.push({path:"/programs"})
        } else if (registered) {
          this.$router.push({path:"/login"})
        }
      })
      .catch(error => {

      }).then(() => {

      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
