<template>
<div v-highlight>

The objective is creating small/efficient/fun chess bots and let them compete. Depending on the category, programs will be limited to a certain size and number of instruction steps they can execute.<br>

<div v-if="game">
  <h3>Live Game</h3>
  <ChessGame :gameinfo="game" style="width: 400px" v-bind:key="game.id" :elochange="false"></ChessGame>
  <br>
</div>

<h3>Inspiration</h3>

This is a crossover of <a href="http://www.pouet.net/prodlist.php">demoscene</a> competitions or <a href="https://www.dwitter.net/">dwitter</a> (which focus on achieving the maximum effect with minimum resources) and chess engine competitions (something with a known objective function).
<br><br>
This video showcases some interesting, simple algorithms: <a href="https://www.youtube.com/watch?v=DpXy041BIlA">30 Weird Chess Algorithms: Elo World</a>

<h3>Rules and format</h3>

A program will participate in all categories it statically qualifies for. If it exceeds the dynamic bounds during execution, it loses that game.
<br><br>
Draws based on the fifty move or threefold repetition rule will be claimed automatically.
<br><br>
We'll be using WebAssembly as runtime, because it is deterministic and supports running untrusted code, and there are a few languages that support it as a compilation target (<a href="https://www.assemblyscript.org/">AssemblyScript</a>, <a href="https://rustwasm.github.io/">Rust</a>, <a href="https://developer.mozilla.org/en-US/docs/WebAssembly/C_to_wasm">C/C++</a>, ...).





<h3>WebAssembly format</h3>

The .wasm binary should export a function move(): i32 that returns a legal move encoded as described further below.

Furthermore, it can access the following external functions that take arguments and return values encoded also as shown below:

<pre><code class="language-typescript">
@external("env", "color")
declare function color(): i32;//returns whether the player is black or white (0 or 1)

@external("env", "board")
declare function board(index: i32): i32;//returns the piece at the given board position

@external("env", "rights")
declare function rights(): i32;//returns en passant and castling rights
</code></pre>

Optionally/usually, the tournament category may also allow access to

<pre><code class="language-typescript">
@external("env", "legal")//returns the nth legal move
declare function legal(index: i32): i32;

@external("env", "maxlegal")//returns the number of possible legal moves
declare function maxlegal(): i32;

@external("env", "randint")//returns a random number between 0 and max (exclusive)
declare function randint(max: i32): i32;

@external("env", "log")//for debugging purposes
declare function log(msg: i32) : i32;
</code></pre>

<h3>Encodings</h3>

<img class="tutorial" src="@/components/assets/img/encodings.png">

<h3>Example programs</h3>

This program tries to move a pawn if possible, otherwise uses a random move.

<pre><code class="language-typescript">
{{ examplecode1 }}
</code></pre>

This program uses the first legal move that takes a piece, if any. Otherwise, it uses a random legal move. It also shows how to get more information about the move and contains definitions of all external functions (though it doesn't use all of them).
<pre><code class="language-typescript">
{{ examplecode2 }}
</code></pre>

<h3>Precedents</h3>

There are a few engines with small size in mind, including historical ones, many due to real physical memory limits like

<li><a href="https://www.chessprogramming.org/1K_ZX_Chess">1K ZX Chess</a></li>

and entries into obfuscated/code golf competitions

<li><a href="https://nanochess.org/chess.html">nanochess</a> which is an amazing 1kb of Javascript (which can be tried <a href="https://nanochess.org/archive/tiny_chess_2.html">here</a>)</li>

<li><a href="https://leanchess.github.io/">leanchess</a> which is just 288 bytes</li>
</div>
</template>

<script>
import examplecode1 from '@/components/assets/example1.txt'
import examplecode2 from '@/components/assets/example2.txt'
import Vue from 'vue'

import vueHljs from "vue-hljs";
//if you want to use default color, import this css file
import "vue-hljs/dist/vue-hljs.min.css";
Vue.use(vueHljs)

import ChessGame from '@/components/ChessGame.vue'

import VueSocketIO from 'vue-socket.io';

Vue.use(new VueSocketIO({
    debug: false,
    connection: window.location.href == "https://rlc-chess.com/" ? "https://rlc-chess.com/" : "http://127.0.0.1:1234",
}))


export default {
  name: 'About',
  props: {
    msg: String,
    game: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    ChessGame
  },
  data: function() {
    return {
    examplecode1,
    examplecode2,
    }
  },

  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
    },

    disconnect() {
      this.isConnected = false;
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data
    },

    livegame(data) {
      this.game = data;
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
